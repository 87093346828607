import * as Types from '../types.generated.js';

import { IntakePatientSlimFragmentDoc } from '../fragments/IntakePatientFragment.generated.js';
import {
  PatientSlimFragmentDoc,
  PatientSlimSansAddressFragmentDoc,
  PatientInsuranceFragmentDoc,
  AddressFragmentDoc,
} from '../fragments/PatientFragment.generated.js';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type CreateOrgPatientMutationVariables = Types.Exact<{
  input: Types.CreateOrgPatientInput;
}>;

export type CreateOrgPatientMutation = {
  __typename?: 'Mutation';
  createOrgPatient?: {
    __typename?: 'CreateOrgPatientMutation';
    patient?: {
      __typename?: 'PatientSlimType';
      id: string;
      emailAddress?: string | null;
      dateOfBirth?: string | null;
      fullName?: string | null;
      firstName: string;
      middleName: string;
      lastName: string;
      sex?: Types.SexEnum | null;
      heightInInches?: number | null;
      weightInPounds?: number | null;
      homePhoneNumber?: string | null;
      shippingAddress?: {
        __typename?: 'AddressType';
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: string;
        id: string;
      } | null;
      guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
      languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
      primaryCareProvider?: {
        __typename?: 'ProviderType';
        id: string;
        firstName: string;
        lastName: string;
        npi: string;
      } | null;
      primaryCareProviderContact?: {
        __typename?: 'ContactType';
        id: string;
        emailAddress?: string | null;
        phoneNumber?: string | null;
        smsNumber?: string | null;
        faxNumber?: string | null;
      } | null;
      patientWaiver?: {
        __typename?: 'PatientWaiverType';
        waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
      } | null;
      primaryInsurance?: {
        __typename?: 'PatientInsuranceType';
        status: Types.AppPatientInsuranceStatusChoices;
        policyNumber: string;
        insurer: {
          __typename?: 'InsurerType';
          id: string;
          insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
          name: string;
          display: string;
          requiresTitleXix: boolean;
          parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
        };
      } | null;
      secondaryInsurance?: {
        __typename?: 'PatientInsuranceType';
        status: Types.AppPatientInsuranceStatusChoices;
        policyNumber: string;
        insurer: {
          __typename?: 'InsurerType';
          id: string;
          insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
          name: string;
          display: string;
          requiresTitleXix: boolean;
          parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
        };
      } | null;
      tertiaryInsurance?: {
        __typename?: 'PatientInsuranceType';
        status: Types.AppPatientInsuranceStatusChoices;
        policyNumber: string;
        insurer: {
          __typename?: 'InsurerType';
          id: string;
          insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
          name: string;
          display: string;
          requiresTitleXix: boolean;
          parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
        };
      } | null;
    } | null;
    error?: { __typename?: 'ErrorType'; id?: string | null; type?: string | null; message: string } | null;
  } | null;
};

export const CreateOrgPatientDocument = `
    mutation createOrgPatient($input: CreateOrgPatientInput!) {
  createOrgPatient(input: $input) {
    patient {
      ...IntakePatientSlimFragment
    }
    error {
      id
      type
      message
    }
  }
}
    ${IntakePatientSlimFragmentDoc}
${PatientSlimFragmentDoc}
${PatientSlimSansAddressFragmentDoc}
${PatientInsuranceFragmentDoc}
${AddressFragmentDoc}`;

export const useCreateOrgPatientMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateOrgPatientMutation, TError, CreateOrgPatientMutationVariables, TContext>,
) => {
  return useMutation<CreateOrgPatientMutation, TError, CreateOrgPatientMutationVariables, TContext>({
    mutationKey: ['createOrgPatient'],
    mutationFn: (variables?: CreateOrgPatientMutationVariables) =>
      fetcher<CreateOrgPatientMutation, CreateOrgPatientMutationVariables>(CreateOrgPatientDocument, variables)(),
    ...options,
  });
};

useCreateOrgPatientMutation.getKey = () => ['createOrgPatient'];

useCreateOrgPatientMutation.fetcher = (
  variables: CreateOrgPatientMutationVariables,
  options?: RequestInit['headers'],
) => fetcher<CreateOrgPatientMutation, CreateOrgPatientMutationVariables>(CreateOrgPatientDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateOrgPatientMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createOrgPatient }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateOrgPatientMutation = (
  resolver: GraphQLResponseResolver<CreateOrgPatientMutation, CreateOrgPatientMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<CreateOrgPatientMutation, CreateOrgPatientMutationVariables>('createOrgPatient', resolver, options);
