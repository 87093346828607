import * as Types from '../types.generated.js';

import { IntakePatientSlimFragmentDoc } from '../fragments/IntakePatientFragment.generated.js';
import {
  PatientSlimFragmentDoc,
  PatientSlimSansAddressFragmentDoc,
  PatientInsuranceFragmentDoc,
  AddressFragmentDoc,
} from '../fragments/PatientFragment.generated.js';
import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetIntakeOrgPatientsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['ID']['input'];
  fullName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  insuranceCarrier?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  policyNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dateOfBirth?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  patientId?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetIntakeOrgPatientsQuery = {
  __typename?: 'Query';
  orgPatients?: {
    __typename?: 'PatientSlimTypeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename?: 'PatientSlimTypeEdge';
      node?: {
        __typename?: 'PatientSlimType';
        id: string;
        emailAddress?: string | null;
        dateOfBirth?: string | null;
        fullName?: string | null;
        firstName: string;
        middleName: string;
        lastName: string;
        sex?: Types.SexEnum | null;
        heightInInches?: number | null;
        weightInPounds?: number | null;
        homePhoneNumber?: string | null;
        shippingAddress?: {
          __typename?: 'AddressType';
          addressLine1: string;
          addressLine2: string;
          city: string;
          state: string;
          postalCode: string;
          id: string;
        } | null;
        guardian?: {
          __typename?: 'PersonType';
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
        } | null;
        languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
        primaryCareProvider?: {
          __typename?: 'ProviderType';
          id: string;
          firstName: string;
          lastName: string;
          npi: string;
        } | null;
        primaryCareProviderContact?: {
          __typename?: 'ContactType';
          id: string;
          emailAddress?: string | null;
          phoneNumber?: string | null;
          smsNumber?: string | null;
          faxNumber?: string | null;
        } | null;
        patientWaiver?: {
          __typename?: 'PatientWaiverType';
          waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
        } | null;
        primaryInsurance?: {
          __typename?: 'PatientInsuranceType';
          status: Types.AppPatientInsuranceStatusChoices;
          policyNumber: string;
          insurer: {
            __typename?: 'InsurerType';
            id: string;
            insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
            name: string;
            display: string;
            requiresTitleXix: boolean;
            parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
          };
        } | null;
        secondaryInsurance?: {
          __typename?: 'PatientInsuranceType';
          status: Types.AppPatientInsuranceStatusChoices;
          policyNumber: string;
          insurer: {
            __typename?: 'InsurerType';
            id: string;
            insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
            name: string;
            display: string;
            requiresTitleXix: boolean;
            parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
          };
        } | null;
        tertiaryInsurance?: {
          __typename?: 'PatientInsuranceType';
          status: Types.AppPatientInsuranceStatusChoices;
          policyNumber: string;
          insurer: {
            __typename?: 'InsurerType';
            id: string;
            insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
            name: string;
            display: string;
            requiresTitleXix: boolean;
            parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
          };
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const GetIntakeOrgPatientsDocument = `
    query getIntakeOrgPatients($orgId: ID!, $fullName: String, $insuranceCarrier: UUID, $policyNumber: String, $dateOfBirth: Date, $patientId: UUID, $offset: Int, $first: Int, $orderBy: String) {
  orgPatients(
    orgId: $orgId
    offset: $offset
    first: $first
    fullName: $fullName
    insuranceCarrier: $insuranceCarrier
    policyNumber: $policyNumber
    dateOfBirth: $dateOfBirth
    id: $patientId
    orderBy: $orderBy
  ) {
    totalCount
    edges {
      node {
        ...IntakePatientSlimFragment
      }
    }
  }
}
    ${IntakePatientSlimFragmentDoc}
${PatientSlimFragmentDoc}
${PatientSlimSansAddressFragmentDoc}
${PatientInsuranceFragmentDoc}
${AddressFragmentDoc}`;

export const useGetIntakeOrgPatientsQuery = <TData = GetIntakeOrgPatientsQuery, TError = unknown>(
  variables: GetIntakeOrgPatientsQueryVariables,
  options?: Omit<UseQueryOptions<GetIntakeOrgPatientsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetIntakeOrgPatientsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetIntakeOrgPatientsQuery, TError, TData>({
    queryKey: ['getIntakeOrgPatients', variables],
    queryFn: fetcher<GetIntakeOrgPatientsQuery, GetIntakeOrgPatientsQueryVariables>(
      GetIntakeOrgPatientsDocument,
      variables,
    ),
    ...options,
  });
};

useGetIntakeOrgPatientsQuery.getKey = (variables: GetIntakeOrgPatientsQueryVariables) => [
  'getIntakeOrgPatients',
  variables,
];

export const useInfiniteGetIntakeOrgPatientsQuery = <TData = InfiniteData<GetIntakeOrgPatientsQuery>, TError = unknown>(
  variables: GetIntakeOrgPatientsQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetIntakeOrgPatientsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetIntakeOrgPatientsQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetIntakeOrgPatientsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getIntakeOrgPatients.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetIntakeOrgPatientsQuery, GetIntakeOrgPatientsQueryVariables>(GetIntakeOrgPatientsDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetIntakeOrgPatientsQuery.getKey = (variables: GetIntakeOrgPatientsQueryVariables) => [
  'getIntakeOrgPatients.infinite',
  variables,
];

useGetIntakeOrgPatientsQuery.fetcher = (
  variables: GetIntakeOrgPatientsQueryVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<GetIntakeOrgPatientsQuery, GetIntakeOrgPatientsQueryVariables>(
    GetIntakeOrgPatientsDocument,
    variables,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetIntakeOrgPatientsQuery(
 *   ({ query, variables }) => {
 *     const { orgId, fullName, insuranceCarrier, policyNumber, dateOfBirth, patientId, offset, first, orderBy } = variables;
 *     return HttpResponse.json({
 *       data: { orgPatients }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetIntakeOrgPatientsQuery = (
  resolver: GraphQLResponseResolver<GetIntakeOrgPatientsQuery, GetIntakeOrgPatientsQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GetIntakeOrgPatientsQuery, GetIntakeOrgPatientsQueryVariables>(
    'getIntakeOrgPatients',
    resolver,
    options,
  );
