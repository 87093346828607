import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  OrderTrackingStatusBadge,
} from '@tomorrow/ui';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { DetailCard, DetailItem } from './DetailCard';
import { displayDate } from '@tomorrow/ui/utils';
import { OrderStatusBadge } from './StatusBadge/OrderStatusBadge';
import { OrgTypes } from '../api-clients/falcon-api/graphql/types.generated';
import { parseDate } from '@tomorrow/ui/utils';
import { PotentialOrderMatchFragment } from '../api-clients/falcon-api/graphql/queries/getOrderSeedPotentialOrderMatches.generated';
import { SxProps } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useStatusDisplayMap } from '../api-clients/falcon-api/hooks/useOrderStatuses';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const SupplierOrderCarousel = ({
  orders,
  selectedOrderId,
  onOrderSelect,
  disabled,
}: {
  orders: PotentialOrderMatchFragment[];
  selectedOrderId?: string;
  onOrderSelect: (orderId: string) => void;
  disabled: boolean;
}) => {
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
  const [selectedCarouselSlideIndex, setSelectedCarouselSlideIndex] = useState<number>(0);

  const onCarouselSlideSelect = useCallback(() => {
    if (!carouselApi) {
      return;
    }

    const selectedIndex = carouselApi.selectedScrollSnap();

    setSelectedCarouselSlideIndex(selectedIndex);
    onOrderSelect(orders[selectedIndex]?.id ?? '');
  }, [carouselApi, onOrderSelect, orders]);

  const onCarouselSlideClick = (index: number) => () => {
    if (!carouselApi) return;

    carouselApi.scrollTo(index);
  };

  useEffect(() => {
    if (!carouselApi) {
      return undefined;
    }

    carouselApi.on('select', onCarouselSlideSelect);

    return () => {
      carouselApi?.off('select', onCarouselSlideSelect);
    };
  }, [carouselApi, onCarouselSlideSelect]);

  useEffect(() => {
    if (!carouselApi) return;

    carouselApi.reInit({
      startIndex: orders.findIndex((order) => order.id === selectedOrderId) ?? 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselApi]);

  useEffect(() => {
    if (!disabled) {
      onCarouselSlideSelect();
    }
  }, [disabled, onCarouselSlideSelect]);

  return (
    <Carousel disabled={disabled} setApi={setCarouselApi}>
      <CarouselContent>
        {orders.map((order, index) => {
          const selected = selectedCarouselSlideIndex === index && !disabled;

          return (
            <CarouselItem key={order.id} sx={{ flexBasis: 'auto' }}>
              <Box
                component="button"
                disabled={disabled}
                onClick={onCarouselSlideClick(index)}
                sx={{
                  width: '100%',
                  bgcolor: 'inherit',
                  pointerEvents: disabled ? 'none' : 'auto',
                  color: 'inherit',
                  border: 'none',
                  padding: '0',
                  outline: 'inherit',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  textAlign: 'inherit',
                  lineHeight: 'inherit',
                }}
                type="button"
              >
                <Box
                  sx={{
                    maxWidth: '380px',
                    minWidth: '350px',
                    minHeight: '200px',
                    borderRadius: 'inherit',
                    border: selected ? `1px solid ${ColorsV2.green}` : '1px solid transparent',
                    bgcolor: selected ? '#F7FFFC' : '#F8F8F8',
                    transition: 'border-color 0.5s, background-color 0.3s',
                    opacity: disabled ? 0.5 : 1,
                  }}
                >
                  <OrderDetailCard order={order} sx={{ padding: '20px' }} />
                </Box>
              </Box>
            </CarouselItem>
          );
        })}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext sx={{ right: 0 }} />
      <CarouselDots />
    </Carousel>
  );
};

const OrderDetailCard = ({
  order,
  compact,
  showOrderId = true,
  sx,
}: {
  order: PotentialOrderMatchFragment;
  compact?: boolean;
  showOrderId?: boolean;
  sx?: SxProps;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        ...sx,
      }}
    >
      {showOrderId && (
        <Typography fontWeight="600" variant="subtitle2">
          {order.friendlyId || order.id}
        </Typography>
      )}
      <DetailCard
        sx={{
          gap: compact ? 0 : 0.5,
          padding: 0,
          bgcolor: 'inherit',
        }}
      >
        <DetailItem disableTypography label="Status">
          <SupplierOrderStatus order={order} />
        </DetailItem>
        <DetailItem label="Created date">{displayDate(parseDate(order.createdAt)) || '-'}</DetailItem>
        <DetailItem label="Subcategory">{order.intakeSubcategory?.name || '-'}</DetailItem>
        {/* TODO: Render items ordered */}
        {/* <DetailItem label="Items ordered">{'-'}</DetailItem> */}
        <DetailItem label="Referring provider">
          {order.referringProvider?.firstName || order.referringProvider?.lastName
            ? `${order.referringProvider.firstName} ${order.referringProvider.lastName}`
            : '-'}
        </DetailItem>
      </DetailCard>
    </Box>
  );
};

export const SupplierOrderStatus = ({ order }: { order: PotentialOrderMatchFragment }) => {
  const statusMap = useStatusDisplayMap(OrgTypes.Supplier);

  if (!order.falconStatus?.status) {
    return null;
  }

  return (
    <Box alignItems="center" display="flex">
      <OrderStatusBadge status={statusMap.get(order.falconStatus.status)} />
      {order.currentAssignment?.trackingNumberSummary?.hasTrackingNumber && (
        <Box display="flex" marginLeft="3px">
          <OrderTrackingStatusBadge
            failedDelivery={!!order.currentAssignment?.trackingNumberSummary.hasFailedTrackingNumber}
          />
        </Box>
      )}
    </Box>
  );
};
