import { FeatureFlagged } from '../../router/FeatureFlagged';
import { IntakeFaxOrderWizardStep } from '../IntakeFaxOrderWizard/IntakeFaxOrderWizard';
import { Layout } from '../../layouts/Layout';
import { lazily } from 'react-lazily';
import { ModalLayout } from '../../layouts/ModalLayout';
import { Navigate, Route } from 'react-router-dom';
import { NavigateToFirstOrgByType } from '../../router/NavigateToFirstOrgByType';
import { OrgTypes } from '../../api-clients/falcon-api/graphql/types.generated';
import { OrgUserContainer } from '../../router/OrgUserContainer';
import { PATHS } from '../../router/paths';
import { retry } from '../../router/retry';
import { SupplierFaxOrderWizard } from '../IntakeFaxOrderWizard/supplier/SupplierFaxOrderWizard';
import { SupplierOmniDrawer } from './SupplierOmniDrawer';
import { useCurrentOrgUser } from '../../router/useCurrentOrgUser';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';

const { SupplierHeader } = lazily(() => retry(() => import('./SupplierHeader')));
const { default: SupplierSettings } = lazily(() => retry(() => import('./SupplierSettings')));
const { SupplierOrderList } = lazily(() => retry(() => import('./SupplierOrderList')));
const { SupplierFaxOrderList } = lazily(() => retry(() => import('./SupplierFaxOrderList')));
const { default: SupplierPerformanceScreen } = lazily(() => retry(() => import('./SupplierPerformanceScreen')));
const { SupplierOrderWizard } = lazily(() =>
  retry(() => import('../IntakeOrderWizard/SupplierIntake/SupplierOrderWizard')),
);
const { NotificationPreferences } = lazily(() =>
  retry(() => import('../../components/NotificationPreferences/NotificationPreferences')),
);
const { default: SupplierCapabilityScreen } = lazily(() => retry(() => import('./SupplierCapabilityScreen')));
const { default: SupplierManagementScreen } = lazily(() => retry(() => import('./SupplierManagementScreen')));
const { default: SupplierPatientOrdersScreen } = lazily(() => retry(() => import('./SupplierPatientOrdersScreen')));
const { IntakeOrderWizardStep } = lazily(() => retry(() => import('../IntakeOrderWizard/IntakeOrderWizard')));
const { PriorAuthCreate } = lazily(() => retry(() => import('./components/PriorAuth/create/PriorAuthCreate')));
const { SupplierPriorAuthList } = lazily(() => retry(() => import('./SupplierPriorAuthList')));

export const supplierRoutes = (
  <Route path="suppliers/*">
    <Route element={<NavigateToFirstOrgByType orgType={OrgTypes.Supplier} />} index />

    <Route element={<OrgUserContainer orgType={OrgTypes.Supplier} />} path=":orgId/*">
      <Route element={<Layout />}>
        <Route element={<SupplierOrderWizard />} path="orders/:orderId/intake/*">
          <Route element={<IntakeOrderWizardStep />} path=":step" />
          <Route element={<Navigate replace to="service" />} index />
        </Route>
      </Route>

      <Route element={<Layout drawer={<SupplierOmniDrawer />} header={<SupplierHeader banner />} />}>
        <Route element={<Navigate replace to="orders" />} index />
        <Route element={<SupplierOrderList />} path="orders" />

        <Route element={<ModalLayout />}>
          <Route
            element={
              <SupplierFaxOrderWizard
                onExitRedirectPath={PATHS.SUPPLIER_ORDERS}
                onFinishRedirectPath={PATHS.SUPPLIER_ORDERS}
              />
            }
            path="orders/faxes/:orderSeedId/intake/qualifications/*"
          >
            <Route element={<IntakeFaxOrderWizardStep />} path=":step" />
            <Route element={<Navigate replace to="document" />} index />
          </Route>
          <Route
            element={
              <SupplierFaxOrderWizard
                onExitRedirectPath={PATHS.SUPPLIER_ORDERS}
                onFinishRedirectPath={PATHS.SUPPLIER_ORDERS}
              />
            }
            path="orders/faxes/:orderSeedId/intake/qualifications_view_only/:documentId/*"
          >
            <Route element={<IntakeFaxOrderWizardStep />} path=":step" />
            <Route element={<Navigate replace to="document" />} index />
          </Route>
        </Route>

        <Route
          element={
            <FeatureFlagged
              enabled={
                <FaxEnabledForSupplierOrgUserGuard>
                  <SupplierFaxOrderList />
                </FaxEnabledForSupplierOrgUserGuard>
              }
              flag="show_supplier_fax_orders_screen"
            />
          }
          path="faxes"
        >
          <Route element={<ModalLayout />}>
            <Route
              element={
                <SupplierFaxOrderWizard
                  onExitRedirectPath={PATHS.SUPPLIER_FAX_ORDERS}
                  onFinishRedirectPath={PATHS.SUPPLIER_ORDERS}
                />
              }
              path=":orderSeedId/intake/*"
            >
              <Route element={<IntakeFaxOrderWizardStep />} path=":step" />
              <Route element={<Navigate replace to="document" />} index />
            </Route>
          </Route>
        </Route>

        <Route path="prior-auth">
          <Route element={<SupplierPriorAuthList />} index />
          <Route element={<PriorAuthCreate />} path="new" />
        </Route>

        <Route
          element={
            <Box py={2}>
              <Container>
                <SupplierCapabilityScreen />
              </Container>
            </Box>
          }
          path="capabilities"
        />
        <Route
          element={<FeatureFlagged enabled={<SupplierPerformanceScreen />} flag="show_supplier_performance_screen" />}
          path="performance"
        />

        <Route
          element={
            <FeatureFlagged enabled={<SupplierPatientOrdersScreen />} flag="show_supplier_patient_orders_screen" />
          }
          path="patients/:patientId"
        />

        <Route element={<Navigate replace to={PATHS.NOT_FOUND} />} path="*" />
      </Route>

      <Route element={<Layout drawer={<SupplierOmniDrawer />} header={<SupplierHeader />} />}>
        <Route element={<SupplierSettings />} path="settings/*">
          <Route element={<NotificationPreferences />} path="notifications" />
          <Route element={<SupplierCapabilityScreen />} path="capabilities" />
          <Route element={<SupplierManagementScreen />} path="my-team" />
          <Route element={<Navigate replace to="notifications" />} index />
        </Route>
      </Route>
    </Route>
    <Route element={<Navigate replace to=".." />} path="*" />
  </Route>
);

function FaxEnabledForSupplierOrgUserGuard({ children }: { children: React.ReactNode }) {
  const currentOrgUser = useCurrentOrgUser();

  return currentOrgUser.org.supplier?.automatedFaxEnabled ? <>{children}</> : <Navigate replace to={PATHS.NOT_FOUND} />;
}
