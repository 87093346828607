import * as Types from '../types.generated.js';

import {
  PatientFragmentDoc,
  PatientInsuranceFragmentDoc,
  AddressFragmentDoc,
  PatientSlimFragmentDoc,
  PatientSlimSansAddressFragmentDoc,
} from './PatientFragment.generated.js';
export type IntakePatientFragment = {
  __typename?: 'PatientType';
  id: string;
  emailAddress?: string | null;
  dateOfBirth?: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  sex?: Types.SexEnum | null;
  heightInInches?: number | null;
  weightInPounds?: number | null;
  homePhoneNumber?: string | null;
  mobilePhoneNumber?: string | null;
  patientWaiver?: {
    __typename?: 'PatientWaiverType';
    waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
  } | null;
  guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
  languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
  primaryCareProvider?: {
    __typename?: 'ProviderType';
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
  } | null;
  primaryCareProviderContact?: {
    __typename?: 'ContactType';
    id: string;
    emailAddress?: string | null;
    phoneNumber?: string | null;
    smsNumber?: string | null;
    faxNumber?: string | null;
  } | null;
  primaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  secondaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  tertiaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  shippingAddress?: {
    __typename?: 'AddressType';
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
    id: string;
  } | null;
};

export type IntakePatientSlimFragment = {
  __typename?: 'PatientSlimType';
  id: string;
  emailAddress?: string | null;
  dateOfBirth?: string | null;
  fullName?: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  sex?: Types.SexEnum | null;
  heightInInches?: number | null;
  weightInPounds?: number | null;
  homePhoneNumber?: string | null;
  shippingAddress?: {
    __typename?: 'AddressType';
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
    id: string;
  } | null;
  guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
  languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
  primaryCareProvider?: {
    __typename?: 'ProviderType';
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
  } | null;
  primaryCareProviderContact?: {
    __typename?: 'ContactType';
    id: string;
    emailAddress?: string | null;
    phoneNumber?: string | null;
    smsNumber?: string | null;
    faxNumber?: string | null;
  } | null;
  patientWaiver?: {
    __typename?: 'PatientWaiverType';
    waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
  } | null;
  primaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  secondaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  tertiaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
};

export type IntakePatientSlimSansAddressFragment = {
  __typename?: 'PatientSlimType';
  id: string;
  emailAddress?: string | null;
  dateOfBirth?: string | null;
  fullName?: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  sex?: Types.SexEnum | null;
  heightInInches?: number | null;
  weightInPounds?: number | null;
  homePhoneNumber?: string | null;
  guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
  languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
  primaryCareProvider?: {
    __typename?: 'ProviderType';
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
  } | null;
  primaryCareProviderContact?: {
    __typename?: 'ContactType';
    id: string;
    emailAddress?: string | null;
    phoneNumber?: string | null;
    smsNumber?: string | null;
    faxNumber?: string | null;
  } | null;
  patientWaiver?: {
    __typename?: 'PatientWaiverType';
    waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
  } | null;
  primaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  secondaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  tertiaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
};

export const IntakePatientFragmentDoc = `
    fragment IntakePatientFragment on PatientType {
  ...PatientFragment
}
    `;
export const IntakePatientSlimFragmentDoc = `
    fragment IntakePatientSlimFragment on PatientSlimType {
  ...PatientSlimFragment
}
    `;
export const IntakePatientSlimSansAddressFragmentDoc = `
    fragment IntakePatientSlimSansAddressFragment on PatientSlimType {
  ...PatientSlimSansAddressFragment
}
    `;
