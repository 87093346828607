import { ColorsV2 } from '../theme';
import { format as dateFnsFormat, isValid, parse } from 'date-fns';
import { RiTimeLine } from '@remixicon/react';
import { TextFieldProps } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

// Currently supports 12 and 24 hour formats via DateFns depedency
const FORMAT_DELIMITER = ':' as const;
export interface TimePickerProps extends Omit<TextFieldProps, 'value' | 'onChange' | 'defaultValue'> {
  value?: Date | null;
  onChange?: (value: Date | null) => void;
  minuteStep?: number;
  format?: '12h' | '24h';
  formatDelimiter?: typeof FORMAT_DELIMITER;
  placeholder?: string;
  hideErrorTooltip?: boolean;
  errorTooltipContent?: React.ReactNode;
}

export function TimePicker({
  disabled,
  onChange,
  value,
  minuteStep = 15,
  format = '24h',
  formatDelimiter = FORMAT_DELIMITER,
  placeholder,
  hideErrorTooltip,
  errorTooltipContent,
  ...rest
}: TimePickerProps) {
  const [inputValue, setInputValue] = useState('');
  const [dateValue, setDateValue] = useState<Date | null>(() => value || null);
  const timeFormat = format === '12h' ? `h${formatDelimiter}mmaaa` : `H${formatDelimiter}mm`;
  const defaultPlaceholder = format === '12h' ? `e.g. 12${formatDelimiter}00pm` : `e.g. 16${formatDelimiter}00`;
  const autocompleteValue = dateValue ? dateFnsFormat(dateValue, timeFormat) : '';
  const timeListOptions = useMemo(() => generateTimeList(minuteStep, timeFormat), [minuteStep, timeFormat]);

  useEffect(() => setDateValue(value || null), [value]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setInputValue(autocompleteValue), [timeFormat]);

  const parsedTime = parse(inputValue, timeFormat, dateValue || new Date());
  // Tricky to strict validate time format so we'll just check if the input length also meets the constraint
  // https://github.com/date-fns/date-fns/issues/2131
  const isParsedTimeValid = isValid(parsedTime) && isValidTimeInputLength(inputValue);

  const handleOnBlur = () => {
    if (!isParsedTimeValid) {
      setInputValue(autocompleteValue);
    }
  };

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      filterOptions={filterOptions}
      freeSolo
      id="time-autocomplete"
      inputValue={inputValue}
      onBlur={handleOnBlur}
      onInputChange={(_e, currentInputValue) => {
        if (!currentInputValue) {
          setDateValue(null);
          onChange?.(null);
          setInputValue('');
          return;
        }

        const parsedTime = parse(currentInputValue, timeFormat, dateValue || new Date());

        // Tricky to strict validate time format so we'll just check if the input length also meets the constraint
        // https://github.com/date-fns/date-fns/issues/2131
        const isParsedTimeValid = isValid(parsedTime) && isValidTimeInputLength(currentInputValue);

        if (isParsedTimeValid) {
          setDateValue(parsedTime);
          onChange?.(parsedTime);
        }

        setInputValue(currentInputValue);
      }}
      openOnFocus
      options={timeListOptions}
      renderInput={(params) => (
        <Tooltip
          arrow
          open
          placement="top-start"
          title={
            inputValue && !isParsedTimeValid && !hideErrorTooltip ? errorTooltipContent || 'Invalid time' : undefined
          }
        >
          <TextField
            {...params}
            {...rest}
            error={(inputValue && !isParsedTimeValid) || rest.error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size={rest.size}>
                    <RiTimeLine color={ColorsV2.navy} size="18px" />
                  </IconButton>
                </InputAdornment>
              ),
              ...rest.InputProps,
            }}
            placeholder={placeholder || defaultPlaceholder}
          />
        </Tooltip>
      )}
      value={autocompleteValue}
    />
  );
}

function isValidTimeInputLength(input: string, formatDelimiter = FORMAT_DELIMITER) {
  const isHourSingleDigit = input[1] === formatDelimiter;

  if (isHourSingleDigit) {
    return input.length > 3;
  }

  return input.length > 4;
}

function generateTimeList(minuteStep: number, format: string) {
  const dTime = new Date(1970, 0, 1);
  const timeList: string[] = [];

  while (dTime.getDate() === 1) {
    timeList.push(dateFnsFormat(dTime, format));

    dTime.setMinutes(dTime.getMinutes() + minuteStep);
  }

  return timeList;
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: string) => option,
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
