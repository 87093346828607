import {
  AppOrderReferralSourceChoices,
  OrderAssignmentTrackingNumberSummaryType,
} from '../../../api-clients/falcon-api/graphql/types.generated';
import { displayDate } from '@tomorrow/ui/utils';
import {
  GetSupplierOrdersQuery,
  GetSupplierOrdersQueryVariables,
  SupplierOrderFragment,
  useGetSupplierOrdersQuery,
} from '../../../api-clients/falcon-api/graphql/queries/getSupplierOrders.generated';
import { logAction } from '../../../utils/logging';
import { milliseconds } from 'date-fns';
import { OrderDisplayStatus, toOrderDisplayStatus } from '../../../api-clients/falcon-api/hooks/useOrderStatuses';
import { parseDate } from '@tomorrow/ui/utils';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { titleCase } from '../../../utils/stringUtils';
import { useEffect, useRef } from 'react';

export type WithDirection<Column extends string> = Column | `-${Column}`;
export type SupplierOrderSortColumn =
  | 'patient__last_name'
  | 'service_type'
  | 'referring_provider__last_name'
  | 'intake_category__name';
export type SupplierOrderSort = WithDirection<SupplierOrderSortColumn>;

export interface DmeOrderForTable extends SupplierOrderFragment {
  deliveryDate?: string;
  displayStatus?: OrderDisplayStatus | null;
  estimatedDeliveryAt?: string | null;
  orderId: string;
  patientName?: string | null;
  policyNumbers: {
    primary?: string;
    secondary?: string;
  };
  trackingNumberSummary?: OrderAssignmentTrackingNumberSummaryType;
  referringProviderName?: string;
  serviceType: string;
  routedAt: string;
  location?: string;
  referralSource?: AppOrderReferralSourceChoices;
}

export default function useSupplierOrders(variables: GetSupplierOrdersQueryVariables, enabled = true) {
  const prevVariables = useRef(variables);
  const query = useQuery({
    async queryFn(context) {
      const data = await useGetSupplierOrdersQuery.fetcher(variables)(context);
      logAction('orders.supplier.legacy', {
        variables,
        results: { totalCount: data.supplierOrders?.totalCount },
      });
      return data;
    },
    queryKey: useGetSupplierOrdersQuery.getKey(variables),
    staleTime: milliseconds({ minutes: 10 }),
    refetchOnMount: 'always',
    enabled: !!variables.supplierId && enabled,
    select: transformGetSupplierOrdersQuery,
    meta: {
      errorMessage: 'Failed to load orders.',
    },
  });

  useEffect(
    function refetchOnStatusChange() {
      if (prevVariables.current.statuses !== variables.statuses) {
        query.refetch();
      }

      prevVariables.current = variables;
    },
    [query, variables],
  );

  return query;
}

const transformOrder = (order: SupplierOrderFragment): DmeOrderForTable => {
  const supplyEventTimeline = order.supplyEventTimeline || [];
  const location = order.mostRecentAssignmentForSupplier?.location?.name;

  return {
    ...order,
    location,
    createdAt: displayDate(parseDate(order.createdAt)) ?? '',
    routedAt: displayDate(parseDate(order.mostRecentAssignmentForSupplier?.createdAt)) ?? '',
    displayStatus: toOrderDisplayStatus(
      supplyEventTimeline[supplyEventTimeline.length - 1]?.statusHistory?.supplyStatus,
    ),
    patientName: order.patient?.fullName,
    policyNumbers: {
      primary: order.patient.primaryInsurance?.policyNumber,
      secondary: order.patient.secondaryInsurance?.policyNumber,
    },
    trackingNumberSummary: order.mostRecentAssignmentForSupplier?.trackingNumberSummary ?? undefined,
    orderId: order.friendlyId || order.id,
    referringProviderName: order.referringProvider
      ? `${titleCase(order.referringProvider.firstName)} ${titleCase(order.referringProvider.lastName)}`
      : undefined,
    serviceType: order.dischargeAt ? 'Discharge' : 'Standard',
    estimatedDeliveryAt: order.mostRecentAssignmentForSupplier?.estimatedDeliveryAt,
    referralSource: order.referralSource ?? undefined,
  };
};

const transformGetSupplierOrdersQuery = (query: GetSupplierOrdersQuery) => {
  return {
    totalCount: query.supplierOrders?.totalCount ?? 0,
    orders: query.supplierOrders?.edges?.flatMap((edge) => (edge?.node ? [transformOrder(edge?.node)] : [])) ?? [],
  };
};

export const refetchSupplierOrderList = async (queryClient: QueryClient) => {
  // When updating orders, cached order data should be invalidated. This will
  // refresh table data as well as the Due count chip on the Accept tab.
  return Promise.all([
    queryClient.invalidateQueries({ queryKey: ['getSupplierOrders'] }),
    queryClient.invalidateQueries({ queryKey: ['getSupplierOrdersLegacy'] }),
    queryClient.invalidateQueries({ queryKey: ['getOrgPatientSupplierOrders'] }),
  ]);
};
