import { AppOrderSeedPrimaryExtractorChoices } from '../../../api-clients/falcon-api/graphql/types.generated';
import { assignOrderSeedDetails, SupplierIntakeFaxOrderSeed } from './SupplierFaxOrderWizard.utils';
import { AutoQualificationsStep } from './steps/AutoQualificationsStep';
import { ColorsV2 } from '@tomorrow/ui/theme';
import {
  DocumentFieldId,
  documentFieldIdToLabelMap,
  mapOrderSeedCoordinateFieldsToHighlightLabel,
} from './steps/DocumentStep.utils';
import { DocumentStep } from './steps/DocumentStep';
import { generatePath, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IntakeFaxOrderWizard, IntakeFaxOrderWizardProps, OrderWizardStep } from '../IntakeFaxOrderWizard';
import { LabelStep } from './steps/LabelStep';
import { logAndShowError } from '../../../utils/errorHandlerHelper';
import { milliseconds } from 'date-fns';
import { OrderStep } from './steps/OrderStep';
import { PatientStep } from './steps/PatientStep';
import { PDFViewerRef } from '@tomorrow/ui';
import { PreviewFaxBackStep } from './steps/PreviewFaxBackStep';
import { produce } from 'immer';
import { QualificationsStep } from './steps/QualificationsStep';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentOrgUser } from '../../../router/useCurrentOrgUser';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useGetDocumentS3UrlQuery } from '../../../api-clients/falcon-api/graphql/queries/getDocumentS3Url.generated';
import { useGetFaxOrderSeedQuery } from '../../../api-clients/falcon-api/graphql/queries/getFaxOrderSeed.generated';
import { useOrderSeedIdParam } from '../../../hooks/useOrderSeedIdParam';
import Box from '@mui/material/Box';
import qs from 'qs';
import SpinnerScreen from '../../../components/Spinner/SpinnerScreen';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const steps = {
  label: {
    path: 'label',
    component: LabelStep,
    stepperLabel: 'Label',
    getCompleted: (orderSeed) => !!orderSeed.id,
  },
  document: {
    path: 'document',
    component: DocumentStep,
    stepperLabel: 'Document',
    getCompleted: (orderSeed) => !!orderSeed.id,
  },
  patient: {
    path: 'patient',
    component: PatientStep,
    stepperLabel: 'Patient',
    getCompleted: (orderSeed) => !!orderSeed.selectedPatient,
  },
  order: {
    path: 'order',
    component: OrderStep,
    stepperLabel: 'Order',
    getCompleted: () => true,
  },
} satisfies Record<string, OrderWizardStep<SupplierIntakeFaxOrderSeed, DocumentFieldId>>;

const qualificationSteps = {
  review: {
    path: 'review',
    component: QualificationsStep,
    stepperLabel: 'Review',
    getCompleted: (orderSeed) => !!orderSeed.id,
  },
  previewFaxBack: {
    path: 'preview',
    component: PreviewFaxBackStep,
    stepperLabel: 'Preview',
    getCompleted: (orderSeed) => !!orderSeed.id,
  },
} satisfies Record<string, OrderWizardStep<SupplierIntakeFaxOrderSeed, DocumentFieldId>>;

// for integration like Synthpop
const autoQualificationSteps = {
  review: {
    path: 'review',
    component: AutoQualificationsStep,
    stepperLabel: 'Review',
    getCompleted: (orderSeed) => !!orderSeed.id && orderSeed.completedAutoQualificationsReviewStep,
  },
  previewFaxBack: {
    path: 'preview',
    component: PreviewFaxBackStep,
    stepperLabel: 'Preview',
    getCompleted: (orderSeed) => !!orderSeed.id,
  },
} satisfies Record<string, OrderWizardStep<SupplierIntakeFaxOrderSeed, DocumentFieldId>>;

type WizardMode = 'default' | 'qualifications' | 'qualifications_view_only';

type SupplierFaxOrderWizardProps = {
  onFinishRedirectPath: string;
  onExitRedirectPath: string;
};

export function SupplierFaxOrderWizard({ onFinishRedirectPath, onExitRedirectPath }: SupplierFaxOrderWizardProps) {
  const flags = useFeatureFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const orderSeedId = useOrderSeedIdParam() ?? '';
  const { org } = useCurrentOrgUser();
  const pdfViewerRef = useRef<PDFViewerRef<DocumentFieldId>>(null);
  const isPageScrolledRef = useRef(false);
  const [orderSeed, setOrderSeed] = useState<SupplierIntakeFaxOrderSeed>({
    orgId: org.id,
    pageLabels: [],
    completedAutoQualificationsReviewStep: false,
    pdfLoading: false,
    showFaxBackPreviewPdf: false,
    faxBackPreviewPdfUrl: null,
  });

  const wizardMode: WizardMode = useMemo(() => {
    if (location.pathname.includes('qualifications_view_only')) {
      return 'qualifications_view_only';
    }

    if (location.pathname.includes('qualifications')) {
      return 'qualifications';
    }

    return 'default';
  }, [location.pathname]);

  const { pdfUrl, documentId, isLoading: isDocumentLoading } = useOrderSeedDocument(orderSeed, wizardMode);

  const stepOrder = useStepOrder(orderSeed, wizardMode);

  const documentHighlightData = useDocumentHighlightData(orderSeed);

  const [isLoading, setIsLoading] = useState(!!orderSeedId);

  useEffect(() => {
    (async function loadOrder() {
      if (!orderSeedId) return;

      try {
        const { orderSeed } = await useGetFaxOrderSeedQuery.fetcher({
          orderSeedId,
        })();

        if (orderSeed) {
          setOrderSeed(produce((current) => assignOrderSeedDetails(current, orderSeed)));
        } else {
          logAndShowError(new Error('Order could not be found'), 'Error retrieving order');
        }
      } catch (e) {
        logAndShowError(e as Error, 'Error retrieving order');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [navigate, orderSeedId, org.id, wizardMode]);

  const handleOrderProduce = useCallback(
    (updater: (orderSeed: SupplierIntakeFaxOrderSeed) => void) => {
      const updatedOrderSeed = produce(updater)(orderSeed);

      setOrderSeed(updatedOrderSeed);

      return updatedOrderSeed;
    },
    [orderSeed],
  );

  const handleOnFinish = useCallback(
    (orderSeed: SupplierIntakeFaxOrderSeed) => {
      const url = `${generatePath(onFinishRedirectPath, { orgId: org.id })}?${qs.stringify({ orderId: orderSeed.order?.id })}`;

      navigate(url);
    },
    [navigate, onFinishRedirectPath, org.id],
  );

  const handleOnExit = useCallback(() => {
    const url = generatePath(onExitRedirectPath, { orgId: org.id });

    navigate(url);
  }, [navigate, onExitRedirectPath, org.id]);

  const handleGetNextStepDisplayText: NonNullable<
    IntakeFaxOrderWizardProps<SupplierIntakeFaxOrderSeed, DocumentFieldId, WizardMode>['getNextStepDisplayText']
  > = useCallback(
    (nextStepExists) => {
      if (nextStepExists) {
        return 'Next';
      }

      if (wizardMode === 'qualifications') {
        return 'Send RP Fax';
      }

      return 'Submit';
    },
    [wizardMode],
  );

  if (isLoading) return <SpinnerScreen fullHeight />;

  if (orderSeedId && !orderSeed.id)
    return <Navigate replace to={generatePath(onExitRedirectPath, { orgId: org.id })} />;

  if (
    (wizardMode === 'qualifications' || wizardMode === 'qualifications_view_only') &&
    !orderSeed.extractionResultsSet
  ) {
    return <Navigate replace to={generatePath(onExitRedirectPath, { orgId: org.id })} />;
  }

  if (wizardMode === 'default' && orderSeed.order?.id) {
    return (
      <Navigate
        replace
        to={`${generatePath(onExitRedirectPath, { orgId: org.id })}?${qs.stringify({ orderId: orderSeed.order?.id, tab: 'Completed' })}`}
      />
    );
  }

  return (
    <IntakeFaxOrderWizard
      documentExtractionJobId={orderSeed.documentExtractionJob?.id ?? ''}
      documentHighlightData={documentHighlightData}
      documentId={documentId}
      extractor={orderSeed.primaryExtractor}
      getNextStepDisplayText={handleGetNextStepDisplayText}
      hideNextStepButton={wizardMode === 'qualifications_view_only'}
      hidePreviousStepButton={wizardMode === 'qualifications_view_only'}
      hideRejectButton={!!orderSeed.order}
      onExit={handleOnExit}
      onFinish={handleOnFinish}
      onOrderProduce={handleOrderProduce}
      onPdfLoadSuccess={(data) => {
        isPageScrolledRef.current = false;

        // If the document has been loaded and the flag is enabled, set the page labels
        if (flags.show_supplier_fax_document_label_step) {
          setOrderSeed((current) => ({
            ...current,
            pageLabels: Array.from({ length: data.numPages }, (_, i) => ({
              data: null,
              page: i,
            })),
          }));
        }
      }}
      orderSeed={orderSeed}
      pdfLoading={orderSeed.pdfLoading || isDocumentLoading}
      pdfOnPageRenderSuccess={() => {
        if (
          (orderSeed.showFaxBackPreviewPdf || wizardMode === 'qualifications_view_only') &&
          !isPageScrolledRef.current
        ) {
          pdfViewerRef.current?.scrollToPage(1, 'smooth');
          isPageScrolledRef.current = true;
        }
      }}
      pdfRenderPage={(content, index) => {
        return (
          <Stack spacing={1}>
            {orderSeed.pageLabels?.[index]?.data?.id && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography
                  sx={{
                    padding: 1,
                    marginTop: 6,
                    borderRadius: 1,
                    bgcolor: ColorsV2.mustard,
                  }}
                >
                  {orderSeed.pageLabels[index].data?.label}
                </Typography>
              </Box>
            )}
            {content}
          </Stack>
        );
      }}
      pdfUrl={orderSeed.showFaxBackPreviewPdf ? orderSeed.faxBackPreviewPdfUrl : pdfUrl}
      pdfViewerRef={pdfViewerRef}
      steps={stepOrder}
      wizardMode={wizardMode}
    />
  );
}

const useDocumentHighlightData = (orderSeed: SupplierIntakeFaxOrderSeed) =>
  useMemo(() => {
    return Object.entries(mapOrderSeedCoordinateFieldsToHighlightLabel(orderSeed)).flatMap(([key, value]) => {
      const coordinateKey = key as DocumentFieldId;

      if (
        value?.boundingBox?.height &&
        value?.boundingBox?.left &&
        value?.boundingBox?.top &&
        value?.boundingBox?.width &&
        value.page
      ) {
        return [
          {
            id: coordinateKey,
            // ! TODO Figure out proper type for label
            // @ts-ignore
            label: documentFieldIdToLabelMap[coordinateKey],
            boundingBox: {
              height: value.boundingBox.height,
              left: value.boundingBox.left,
              top: value.boundingBox.top,
              width: value.boundingBox.width,
            },
            pageNumber: value.page,
          },
        ];
      }

      return [];
    });
  }, [orderSeed]);

const stepOrderDefaultFlow = [steps.document, steps.patient, steps.order];
const stepOrderQualificationsFlow = [qualificationSteps.review, qualificationSteps.previewFaxBack];
const stepOrderAutoQualificationsFlowViewOnly = [autoQualificationSteps.review];
const stepOrderAutoQualificationsFlow = [autoQualificationSteps.review, qualificationSteps.previewFaxBack];

function useStepOrder(orderSeed: SupplierIntakeFaxOrderSeed, wizardMode: WizardMode) {
  const flags = useFeatureFlags();

  return useMemo(() => {
    if (
      wizardMode === 'qualifications_view_only' &&
      orderSeed.primaryExtractor === AppOrderSeedPrimaryExtractorChoices.Synthpop
    ) {
      return stepOrderAutoQualificationsFlowViewOnly;
    }

    if (
      wizardMode === 'qualifications' &&
      orderSeed.primaryExtractor === AppOrderSeedPrimaryExtractorChoices.Synthpop
    ) {
      return stepOrderAutoQualificationsFlow;
    }

    const stepOrder = wizardMode === 'qualifications' ? stepOrderQualificationsFlow : stepOrderDefaultFlow;

    return flags.show_supplier_fax_document_label_step ? [steps.label, ...stepOrder] : stepOrder;
  }, [flags.show_supplier_fax_document_label_step, orderSeed.primaryExtractor, wizardMode]);
}

const useOrderSeedDocument = (orderSeed: SupplierIntakeFaxOrderSeed, wizardMode: WizardMode) => {
  const params = useParams<{ documentId: string }>();
  const transmittedDocumentId = params.documentId;

  const documentId =
    wizardMode === 'qualifications_view_only'
      ? (transmittedDocumentId ?? '')
      : (orderSeed.documentExtractionJob?.searchableDocument?.id ?? orderSeed.documentExtractionJob?.document.id ?? '');

  const s3UrlQuery = useGetDocumentS3UrlQuery(
    { documentId },
    { enabled: !!documentId, staleTime: milliseconds({ minutes: 10 }) },
  );

  const pdfUrl =
    s3UrlQuery.data?.documentS3Url?.__typename === 'DocumentS3UrlResult'
      ? (s3UrlQuery.data.documentS3Url.url ?? undefined)
      : undefined;

  return {
    pdfUrl,
    documentId,
    isLoading: s3UrlQuery.isLoading,
  };
};
