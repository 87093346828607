import {
  AppDocumentExtractionJobAssignmentRoleChoices,
  AppOrderSeedPrimaryExtractorChoices,
} from '../../api-clients/falcon-api/graphql/types.generated';
import { clearCachedOrderData } from '../IntakeOrderWizard/common/omniApi';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { ComponentProps, MemoExoticComponent, useCallback, useMemo, useRef, useState } from 'react';
import { FaxWizardRejectionFormValues, FaxWizardToolbar } from './common/components/FaxWizardToolbar';
import { logAndShowError } from '../../utils/errorHandlerHelper';
import { logFaxWizardAction, useTrackStepNavigation } from './IntakeFaxOrderWizard.helpers';
import { Navigate, Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { PDFViewer } from '../../components/PDFViewer';
import { PDFViewerProps, PDFViewerRef, ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@tomorrow/ui';
import { RiFileUnknowLine } from '@remixicon/react';
import { useCurrentOrgUser } from '../../router/useCurrentOrgUser';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useOrgRevokeDocumentExtractionJobMutation } from '../../api-clients/falcon-api/graphql/mutations/orgRevokeDocumentExtractionJob.generated';
import { useQueryClient } from '@tanstack/react-query';
import { useUserLocalStorage } from '../../hooks/useUserStorage';
import Box from '@mui/material/Box';
import SpinnerScreen from '../../components/Spinner/SpinnerScreen';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type StepRef = {
  triggerFormSubmit: () => Promise<void>;
};

export type HighlightFieldCb<T extends string> = (targetElement: HTMLElement, fieldId: T, enabled: boolean) => void;

export interface OrderWizardStepComponentProps<OrderSeed, TPDFViewerFieldId extends string = any> {
  orderSeed: OrderSeed;
  pdfViewerRef: React.RefObject<PDFViewerRef<TPDFViewerFieldId>>;
  logIntakeAction: (name: string, payload?: { [key: string]: unknown }) => void;
  onHighlightField: HighlightFieldCb<TPDFViewerFieldId>;
  onOrderProduce: (updater: (orderSeed: OrderSeed) => void) => OrderSeed;
  onNextStep: (orderSeed: OrderSeed) => void;
  onOrderSubmit: () => void;
}

type OrderWizardStepComponent<OrderSeed, TPDFViewerFieldId extends string> = React.ForwardRefExoticComponent<
  OrderWizardStepComponentProps<OrderSeed, TPDFViewerFieldId> & React.RefAttributes<StepRef>
>;

export interface OrderWizardStep<OrderSeed, TPDFViewerFieldId extends string> {
  path: string;
  component:
    | OrderWizardStepComponent<OrderSeed, TPDFViewerFieldId>
    | MemoExoticComponent<OrderWizardStepComponent<OrderSeed, TPDFViewerFieldId>>;
  stepperLabel: string;
  stepperComponent?: React.ComponentType<{ orderSeed: OrderSeed }>;
  getCompleted: (orderSeed: OrderSeed) => boolean;
  getDisabled?: (orderSeed: OrderSeed) => boolean;
}

export interface IntakeFaxOrderWizardProps<OrderSeed, TPDFViewerFieldId extends string, TWizardMode extends string> {
  wizardMode: TWizardMode;
  orderSeed: OrderSeed;
  pdfViewerRef: React.RefObject<PDFViewerRef<TPDFViewerFieldId>>;
  pdfOnPageRenderSuccess: PDFViewerProps<TPDFViewerFieldId>['onPageRenderSuccess'];
  pdfRenderPage: PDFViewerProps<TPDFViewerFieldId>['renderPage'];
  onPdfLoadSuccess: PDFViewerProps<TPDFViewerFieldId>['onLoadSuccess'];
  documentId: string;
  pdfUrl: PDFViewerProps<TPDFViewerFieldId>['file'];
  pdfLoading: boolean;
  documentExtractionJobId: string;
  hidePreviousStepButton: boolean;
  hideNextStepButton: boolean;
  hideRejectButton: boolean;
  extractor: AppOrderSeedPrimaryExtractorChoices | undefined;
  documentHighlightData: ComponentProps<typeof PDFViewer<TPDFViewerFieldId>>['highlightData'] | undefined;
  onOrderProduce: (updater: (orderSeed: OrderSeed) => void) => OrderSeed;
  steps: OrderWizardStep<OrderSeed, TPDFViewerFieldId>[];
  getNextStepDisplayText?: (nextStepExists: boolean) => string;
  onExit: () => void;
  onFinish: (orderSeed: OrderSeed) => void;
}

export function IntakeFaxOrderWizard<OrderSeed, TPDFViewerFieldId extends string, TWizardMode extends string>(
  props: IntakeFaxOrderWizardProps<OrderSeed, TPDFViewerFieldId, TWizardMode>,
) {
  return <Outlet context={props} />;
}

export function IntakeFaxOrderWizardStep() {
  const { step } = useParams<{ step: string }>();
  const context = useOutletContext<IntakeFaxOrderWizardProps<unknown, string, string>>();
  const { steps, orderSeed } = context;
  const { isLoading: isUserStorageDataLoading } = useUserLocalStorage();

  const currentStepIndex = steps.findIndex(({ path }) => step === path);
  const currentStep = steps[currentStepIndex];

  const isCurrentStepAccessible = useMemo(() => {
    if (currentStepIndex < 0 || currentStep?.getDisabled?.(orderSeed)) return false;

    const arePreviousStepsComplete = steps.slice(0, currentStepIndex).every((s) => s.getCompleted(orderSeed));
    return arePreviousStepsComplete;
  }, [currentStep, currentStepIndex, orderSeed, steps]);

  if (isUserStorageDataLoading) return null;

  if (!isCurrentStepAccessible) {
    const earliestAccessibleIncompleteStep = steps.find(
      (s) => !s.getDisabled?.(orderSeed) && !s.getCompleted(orderSeed),
    );
    const newPath = (earliestAccessibleIncompleteStep || steps[steps.length - 1]).path;

    if (step !== newPath) {
      return <Navigate replace to={`../${newPath}`} />;
    }
  }

  return <IntakeFaxOrderWizardStepInternal {...context} currentStepIndex={currentStepIndex} />;
}

const defaultNextStepDisplayText = (nextStepExists: boolean) => (nextStepExists ? 'Next' : 'Finish');

function IntakeFaxOrderWizardStepInternal<OrderSeed, TPDFViewerFieldId extends string, TWizardMode extends string>({
  steps,
  orderSeed,
  wizardMode,
  documentId,
  pdfUrl,
  pdfLoading,
  documentExtractionJobId,
  documentHighlightData,
  pdfViewerRef,
  extractor,
  hideNextStepButton,
  hidePreviousStepButton,
  hideRejectButton,
  onOrderProduce,
  onExit,
  onFinish,
  pdfOnPageRenderSuccess,
  onPdfLoadSuccess,
  pdfRenderPage,
  getNextStepDisplayText = defaultNextStepDisplayText,
  currentStepIndex,
}: IntakeFaxOrderWizardProps<OrderSeed, TPDFViewerFieldId, TWizardMode> & { currentStepIndex: number }) {
  const flags = useFeatureFlags();
  const navigate = useNavigate();
  const currentOrgUser = useCurrentOrgUser();
  const queryClient = useQueryClient();

  const step = steps[currentStepIndex];
  const StepComponent = step.component;
  const stepRef = useRef<StepRef>(null);

  const [loading, setLoading] = useState(false);

  const { setLogNavActionTypeState, traceId } = useTrackStepNavigation(
    documentExtractionJobId,
    wizardMode,
    extractor,
    step.path,
  );

  const revokeDocumentExtractionJobMutation = useOrgRevokeDocumentExtractionJobMutation();

  const handleReject = useCallback(
    async ({ reasonCode, otherReasonText }: FaxWizardRejectionFormValues) => {
      try {
        if (!documentExtractionJobId) {
          throw new Error('Document Extraction Job ID is missing');
        }

        setLogNavActionTypeState('reject');

        setLoading(true);

        const responseData = await revokeDocumentExtractionJobMutation.mutateAsync({
          input: {
            documentExtractionJobId,
            orgId: currentOrgUser.org.id,
            role: AppDocumentExtractionJobAssignmentRoleChoices.ExceptionHandler,
            revokeReason: reasonCode,
            revokeReasonDescription: otherReasonText ?? undefined,
          },
        });

        if (responseData.orgRevokeDocumentExtractionJob?.error) {
          throw new Error(responseData.orgRevokeDocumentExtractionJob.error.message);
        }
      } catch (error) {
        logAndShowError(error, 'Could not save order details');
      }

      clearCachedOrderData(queryClient);

      setLoading(false);

      onExit();
    },
    [
      queryClient,
      onExit,
      documentExtractionJobId,
      setLogNavActionTypeState,
      revokeDocumentExtractionJobMutation,
      currentOrgUser.org.id,
    ],
  );

  const handleExit = useCallback(() => {
    setLogNavActionTypeState('exit');
    onExit();
  }, [setLogNavActionTypeState, onExit]);

  const handleOnOrderSubmit = useCallback(() => {
    logFaxWizardAction(`fax_order_intake_submit`, {
      stepPath: step.path,
      documentExtractionJobId,
      traceId,
      wizardMode,
      extractor,
      navActionType: null,
    });
  }, [documentExtractionJobId, extractor, step.path, traceId, wizardMode]);

  const previousStep = useMemo(
    () =>
      steps
        .slice(0, currentStepIndex)
        .reverse()
        .find((step) => !step.getDisabled?.(orderSeed)),
    [currentStepIndex, orderSeed, steps],
  );

  const nextStep = useMemo(() => {
    const remainingSteps = steps.slice(currentStepIndex + 1);
    const nextStep = remainingSteps.find((step) => !step.getDisabled?.(orderSeed));

    return nextStep;
  }, [currentStepIndex, orderSeed, steps]);

  const handlePrevStep = useCallback(() => {
    if (previousStep) {
      setLogNavActionTypeState('prev');

      navigate(`../${previousStep.path}`);
    }
  }, [navigate, previousStep, setLogNavActionTypeState]);

  const handleNextStep = useCallback(
    (orderSeed: OrderSeed) => {
      if (nextStep) {
        setLogNavActionTypeState('next');

        navigate(`../${nextStep.path}`);
      } else {
        setLogNavActionTypeState('end');
        onFinish(orderSeed);
      }
    },
    [nextStep, setLogNavActionTypeState, navigate, onFinish],
  );

  const onLogIntakeAction: OrderWizardStepComponentProps<OrderSeed, TPDFViewerFieldId>['logIntakeAction'] = useCallback(
    (name, payloadMeta) => {
      logFaxWizardAction(name, {
        stepPath: step.path,
        documentExtractionJobId,
        traceId,
        wizardMode,
        extractor,
        navActionType: null,
        meta: payloadMeta,
      });
    },
    [documentExtractionJobId, extractor, step.path, traceId, wizardMode],
  );

  const [highlightSegmentCoords, setHighlightSegmentCoords] = useState<BezierLineSegmentPoints | null>(null);

  const handleOnHighlightField: OrderWizardStepComponentProps<OrderSeed, TPDFViewerFieldId>['onHighlightField'] =
    useCallback(
      async (targetElement, fieldId, enabled) => {
        if (!flags.fax_intake_flow_highlights) return;

        const highlightOverlayRef = await pdfViewerRef?.current?.highlightField(fieldId, enabled);
        const highlightOverlayElement = highlightOverlayRef?.current?.element ?? null;

        if (highlightOverlayElement && enabled) {
          setHighlightSegmentCoords(calculateBezierLineSegmentPoints(highlightOverlayElement, targetElement));
        } else {
          setHighlightSegmentCoords(null);
        }
      },
      [flags.fax_intake_flow_highlights, pdfViewerRef],
    );

  return (
    <Box bgcolor="#F2F3F3" display="flex" flexDirection="column" flexGrow="1" maxHeight="100vh" overflow="auto">
      <Box display="flex" flexGrow="1" overflow="auto" position="relative">
        {highlightSegmentCoords && (
          <BezierLineSegment
            controlPoints={highlightSegmentCoords.controlPoints}
            endPos={highlightSegmentCoords.endPos}
            startPos={highlightSegmentCoords.startPos}
          />
        )}

        <ResizablePanelGroup direction="horizontal" style={{ display: 'flex', overflow: 'auto' }}>
          <ResizablePanel defaultSize={60} minSize={40} style={{ overflow: 'auto', display: 'flex' }}>
            <PDFViewer
              ref={pdfViewerRef}
              file={pdfUrl}
              fileName={`Fax Form ${documentId}.pdf`}
              highlightColor={ColorsV2.green}
              highlightData={documentHighlightData}
              loading={<SpinnerScreen />}
              noData={
                pdfLoading ? (
                  <SpinnerScreen />
                ) : (
                  <Stack alignItems="center" color="text.secondary" height="100%" justifyContent="center" spacing={1}>
                    <RiFileUnknowLine opacity={0.45} size="52px" />
                    <Typography variant="subtitle2">PDF document not found.</Typography>
                  </Stack>
                )
              }
              onLoadError={(err) => logAndShowError(err, 'Cannot display PDF')}
              onLoadSuccess={onPdfLoadSuccess}
              onPageRenderSuccess={pdfOnPageRenderSuccess}
              onToolbarClick={(clickType) => {
                switch (clickType) {
                  case 'download':
                    logFaxWizardAction(`fax_order_intake_pdf_viewer_download_pdf_clicked`, {
                      stepPath: step.path,
                      documentExtractionJobId,
                      traceId,
                      wizardMode,
                      extractor,
                      navActionType: null,
                    });
                    break;

                  default:
                    break;
                }
              }}
              renderPage={pdfRenderPage}
            />
          </ResizablePanel>

          <ResizableHandle withHandle />

          <ResizablePanel
            defaultSize={40}
            minSize={35}
            style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}
          >
            <Box display="flex" flexGrow={1} role="region">
              <StepComponent
                ref={stepRef}
                logIntakeAction={onLogIntakeAction}
                onHighlightField={handleOnHighlightField}
                onNextStep={handleNextStep}
                onOrderProduce={onOrderProduce}
                onOrderSubmit={handleOnOrderSubmit}
                orderSeed={orderSeed}
                pdfViewerRef={pdfViewerRef}
              />
            </Box>
          </ResizablePanel>
        </ResizablePanelGroup>
      </Box>
      <Box
        bgcolor="white"
        borderTop={`1px solid ${ColorsV2.gray_light}`}
        boxShadow="0px 4px 44px 0px rgba(0, 0, 0, 0.15)"
        padding="15px 20px"
      >
        <FaxWizardToolbar
          hideNextStepButton={hideNextStepButton}
          hidePrevStepButton={hidePreviousStepButton}
          hideRejectButton={hideRejectButton}
          loading={loading}
          nextStepDisplayText={getNextStepDisplayText(!!nextStep)}
          onClose={handleExit}
          onNextStep={async () => {
            try {
              setLoading(true);

              // Trigger current step's form submit before navigating to the next step
              await stepRef.current?.triggerFormSubmit();
            } catch (error) {
              logAndShowError(error, 'Could not save order details');
            }

            setLoading(false);
          }}
          onPrevStep={previousStep ? handlePrevStep : undefined}
          onReject={handleReject}
        >
          <Typography noWrap>Document {documentExtractionJobId}</Typography>
        </FaxWizardToolbar>
      </Box>
    </Box>
  );
}

type BezierLineSegmentPoints = ReturnType<typeof calculateBezierLineSegmentPoints>;

const calculateBezierLineSegmentPoints = (element1: HTMLElement, element2: HTMLElement) => {
  // Get the positions of the elements
  const rect1 = element1?.getBoundingClientRect();
  const rect2 = element2?.getBoundingClientRect();

  // Calculate the start and end positions
  const x1 = rect1.left + rect1.width;
  const y1 = rect1.top + rect1.height / 2;
  const x2 = rect2.left;
  const y2 = rect2.top + rect2.height / 2;

  // Calculate the control points for the Bezier curve
  const cx1 = (x1 + x2) / 2;
  const cy1 = y1;
  const cx2 = (x1 + x2) / 2;
  const cy2 = y2;

  return {
    startPos: { x: x1, y: y1 },
    endPos: { x: x2, y: y2 },
    controlPoints: { cx1, cy1, cx2, cy2 },
  };
};

const BezierLineSegment = ({ startPos, endPos, controlPoints }: BezierLineSegmentPoints) => {
  return (
    <Box
      component="svg"
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 999,
      }}
    >
      <path
        d={`M${startPos.x},${startPos.y} C${controlPoints.cx1},${controlPoints.cy1} ${controlPoints.cx2},${controlPoints.cy2} ${endPos.x},${endPos.y}`}
        fill="none"
        stroke={ColorsV2.green}
        strokeWidth="1.5px"
      />
    </Box>
  );
};
