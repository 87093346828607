import { AutocompleteGetTagProps } from '@mui/material';
import { forwardRef, useState } from 'react';
import {
  GetHcpcsCodesPaginatedQuery,
  HcpcsCodeFragment,
  useGetHcpcsCodesPaginatedQuery,
} from '../api-clients/falcon-api/graphql/queries/getHcpcsCodesPaginated.generated';
import { keepPreviousData } from '@tanstack/react-query';
import { milliseconds } from 'date-fns';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useDebounce from '../hooks/useDebounce';

export interface HcpcsCode extends HcpcsCodeFragment {
  id: HcpcsCodeFragment['code'];
}

export interface HcpcsCodeAutocompleteProps {
  error: string | undefined;
  selectedHcpcsCodes: HcpcsCode | HcpcsCode[] | undefined;
  setSelectedHcpcsCodes: (hcpcsCodes: HcpcsCode | HcpcsCode[] | null) => void;
  disabled?: boolean;
  placeholder?: string;
  multiple?: boolean;
  id?: string;
  size?: 'small' | 'medium';
}

const filterOptions = createFilterOptions<HcpcsCode>({
  stringify: (option) => `${option.code} ${getDescription(option)}`,
});

export const HcpcsCodeAutocomplete = forwardRef<HTMLElement, HcpcsCodeAutocompleteProps>(function HcpcsCodeAutocomplete(
  { error, selectedHcpcsCodes, setSelectedHcpcsCodes, disabled, placeholder, multiple = true, size = 'small', id },
  ref,
) {
  const [hcpcsCodeOptionsSearchValue, setHcpcsCodeOptionsSearchValue] = useState('');
  const hcpcsCodeSearchQuery = useDebounce(hcpcsCodeOptionsSearchValue, 300);
  const hcpcsCodeOptions = useHcpcsCodeOptions(hcpcsCodeSearchQuery);
  const totalHcpcsCodeCount = useTotalHcpcsCodeCount();
  const hasValue = Array.isArray(selectedHcpcsCodes) ? selectedHcpcsCodes.length > 0 : !!selectedHcpcsCodes;
  const noHcpcsCodeError =
    totalHcpcsCodeCount.isSuccess && !totalHcpcsCodeCount.data && !hasValue ? 'No HCPCS codes found' : '';

  const errorText = error ?? noHcpcsCodeError;
  const isDisabled = disabled || !!noHcpcsCodeError;

  function handleTextboxChange(input: string) {
    setHcpcsCodeOptionsSearchValue(input);
  }

  return (
    <Autocomplete
      disabled={isDisabled}
      filterOptions={filterOptions}
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      id={id}
      isOptionEqualToValue={getOptionSelected}
      limitTags={3}
      loading={hcpcsCodeOptions.isFetching}
      multiple={multiple}
      noOptionsText={
        hcpcsCodeOptionsSearchValue ? 'No options found. Try searching for more.' : 'Try searching for options'
      }
      onChange={(_e, value) => setSelectedHcpcsCodes(value)}
      options={hcpcsCodeOptions.data ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errorText}
          helperText={errorText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {hcpcsCodeOptions.isFetching ? (
                  <Box position="absolute" right="37px">
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            size,
          }}
          inputRef={ref}
          onChange={(event) => handleTextboxChange(event.target.value)}
          placeholder={placeholder ?? 'Search code or description'}
        />
      )}
      renderOption={getOptionRender}
      renderTags={renderTags}
      size={size}
      value={selectedHcpcsCodes}
    />
  );
});

function renderTags(value: HcpcsCode[], getTagProps: AutocompleteGetTagProps) {
  return value.map((option, index) => (
    // @ts-ignore
    <Chip key={option.id} {...getTagProps({ index })} label={getTagRender(option)} />
  ));
}

function getTagRender(hcpcsCode: HcpcsCode) {
  return (
    <Box component={Typography} title={`${hcpcsCode.code} - ${getDescription(hcpcsCode)}`}>
      {hcpcsCode.code}
    </Box>
  );
}

function getOptionLabel({ code }: HcpcsCode) {
  return code;
}

function getDescription({ longDescription, friendlyDescription }: HcpcsCode) {
  return friendlyDescription || longDescription || '';
}

function getOptionRender(props: React.HTMLAttributes<HTMLLIElement>, hcpcsCode: HcpcsCode) {
  return (
    <li {...props}>
      <Box component={Typography} overflow="hidden" textOverflow="ellipsis" title={getDescription(hcpcsCode)}>
        <strong>{hcpcsCode.code}</strong>
        {Boolean(getDescription(hcpcsCode)) && (
          <>
            <Typography component="span" variant="body2">
              {' '}
              - {getDescription(hcpcsCode)}
            </Typography>
          </>
        )}
      </Box>
    </li>
  );
}

function getOptionSelected(option: HcpcsCode, hcpcCode: HcpcsCode) {
  return option.code === hcpcCode.code;
}

function useTotalHcpcsCodeCount() {
  return useGetHcpcsCodesPaginatedQuery(
    { first: 0 },
    {
      staleTime: milliseconds({ minutes: 5 }),
      placeholderData: keepPreviousData,
      meta: {
        errorMessage: 'Failed to retrieve total HCPCS code count',
      },
      select: transformGetHcpcsCodesQueryForCount,
    },
  );
}

function transformGetHcpcsCodesQueryForCount(query: GetHcpcsCodesPaginatedQuery) {
  return query.hcpcsCodesPaginated?.totalCount;
}

function useHcpcsCodeOptions(searchText: string) {
  return useGetHcpcsCodesPaginatedQuery(
    { first: 10, searchText },
    {
      enabled: searchText.length > 1,
      staleTime: milliseconds({ minutes: 5 }),
      placeholderData: keepPreviousData,
      meta: {
        errorMessage: 'Failed to retrieve hcpcs codes',
      },
      select: transformGetHcpcsCodesQuery,
    },
  );
}

function transformGetHcpcsCodesQuery(query: GetHcpcsCodesPaginatedQuery): HcpcsCode[] {
  return (
    query.hcpcsCodesPaginated?.edges?.flatMap((edge) => (edge?.node ? [{ ...edge.node, id: edge.node.code }] : [])) ??
    []
  );
}
