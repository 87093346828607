import { forwardRef, useState } from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type Option = {
  id?: string;
  label: string;
  value: string;
  optionIndex?: string;
};

interface AutocompleteProps {
  disabled?: boolean;
  id?: string;
  value: string;
  onSelect: (event: unknown) => void;
  errorText?: string;
  placeholder?: string;
  onFocus: () => void;
  onBlur: (e?: unknown) => void;
  options: Option[];
  size?: 'small' | 'medium';
  inputLabel?: string;
  required?: boolean;
}

export const Autocomplete = forwardRef<HTMLElement, AutocompleteProps>(function Autocomplete(
  {
    disabled,
    id,
    onSelect,
    value,
    onBlur,
    onFocus,
    errorText,
    placeholder,
    options,
    inputLabel,
    required,
    size = 'small',
  },
  ref,
) {
  const [open, setOpen] = useState(false);

  const mappedOptions: Option[] = options.map((option, index) => ({
    ...option,
    optionIndex: index.toString(),
  }));

  const selectOption = (_e: React.SyntheticEvent, selectValue: Option | null) => {
    onSelect(selectValue?.value || '');
  };

  return (
    <MuiAutocomplete
      autoComplete={false}
      disabled={disabled}
      getOptionLabel={({ label }: Option) => label}
      id={id}
      isOptionEqualToValue={(option: Option, optionValue) => option.value === optionValue?.value}
      onBlur={onBlur}
      onChange={selectOption}
      onClose={() => {
        setOpen(false);
      }}
      onFocus={onFocus}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      options={mappedOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errorText}
          helperText={errorText}
          id={id ? `${id}-input` : undefined}
          inputProps={{
            ...params.inputProps,
            'aria-label': inputLabel,
          }}
          inputRef={ref}
          label={inputLabel}
          placeholder={placeholder}
          required={required}
          size={size || 'medium'}
          value={value}
        />
      )}
      renderOption={(props, { label }) => <li {...props}>{label}</li>}
      size={size}
      value={options.find((option) => option.value === value) ?? null}
    />
  );
});
