import { AddressAutocomplete, SmartyStreetsPrediction } from './AddressAutocomplete';
import { DiagnosisCodeAutocomplete } from './DiagnosisCodeAutocomplete';
import { FieldPath, FieldPathValue, FieldValues, useController, UseControllerProps, useWatch } from 'react-hook-form';
import { GetIntakeSubcategoriesQuery } from '../api-clients/falcon-api/graphql/queries/getIntakeSubcategories.generated';
import { HcpcsCodeAutocomplete } from './HcpcsCodeAutocomplete';
import { isValid } from 'date-fns';
import { isValidEmailAddress } from '../utils/isValidEmailAddress';
import { NpiProvider, useValidateNpi } from '../hooks/useValidateNpi';
import { PatientAutocomplete } from './PatientAutocomplete';
import { PhoneInput } from './PhoneInput';
import { Select } from './Select/Select';
import { sexOptions } from '../utils/gender';
import { states } from '../utils/states';
import { useCallback, useMemo } from 'react';
import { useIntakeSubcategories } from '../api-clients/falcon-api/hooks/useIntakeSubcategories';
import { validatePhone } from '../utils/phoneUtils';
import DatePicker from './DatePicker/DatePicker';
import TextField from '@mui/material/TextField';

export interface FormInputProps<TFieldValues extends FieldValues> extends UseControllerProps<TFieldValues> {
  multiline?: boolean;
  rows?: number;
  label?: string;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  onFocus?: () => void;
  onBlur?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export function Input<TFieldValues extends FieldValues>({
  multiline,
  rows,
  label,
  placeholder = '',
  type = 'text',
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  ...props
}: FormInputProps<TFieldValues>) {
  const {
    field: { ref, ...field },
    fieldState,
  } = useController(props);

  return (
    <TextField
      {...field}
      error={!!fieldState.error?.message}
      helperText={fieldState.error?.message}
      id={field.name}
      inputRef={ref}
      label={label}
      multiline={multiline}
      onBlur={() => {
        field.onBlur();
        onBlur?.();
      }}
      onChange={(e) => field.onChange(String(e.target.value))}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      placeholder={placeholder}
      required={!!props.rules?.required}
      rows={rows}
      size="small"
      type={type}
    />
  );
}
export function EmailAddressInput<TFieldValues extends FieldValues>(props: FormInputProps<TFieldValues>) {
  return (
    <Input
      {...props}
      rules={{
        validate: (value) => {
          if (!value) return undefined;
          const isEmailValid = isValidEmailAddress(value);
          if (!isEmailValid) return 'Must be a valid email address';
          return undefined;
        },
        ...props.rules,
      }}
      type="email"
    />
  );
}

export function GenderInput<TFieldValues extends FieldValues>({
  label,
  ...props
}: Omit<FormInputProps<TFieldValues>, 'type'>) {
  const { field, fieldState } = useController(props);

  return (
    <Select
      {...field}
      error={!!fieldState.error?.message}
      errorText={fieldState.error?.message}
      id={field.name}
      label={label}
      options={sexOptions}
      required={!!props.rules?.required}
      size="small"
    />
  );
}

export function NumberInput<TFieldValues extends FieldValues>(props: FormInputProps<TFieldValues>) {
  return (
    <Input
      {...props}
      rules={{
        validate(value) {
          if (!value) return undefined;
          const num = Number(value);
          return !Number.isInteger(num) || num < 1 ? 'Field should be a number greater than 0' : undefined;
        },
        ...props.rules,
      }}
    />
  );
}

export function PhoneNumberInput<TFieldValues extends FieldValues>({
  label,
  type = 'tel',
  ...props
}: FormInputProps<TFieldValues>) {
  const { field, fieldState } = useController({
    ...props,
    rules: {
      validate: validatePhone('Phone'),
      ...props.rules,
    },
  });

  return (
    <PhoneInput
      {...field}
      error={!!fieldState.error?.message}
      helperText={fieldState.error?.message}
      id={field.name}
      label={label}
      required={!!props.rules?.required}
      size="small"
      type={type}
    />
  );
}

export function FaxNumberInput<TFieldValues extends FieldValues>({
  label,
  type = 'tel',
  ...props
}: FormInputProps<TFieldValues>) {
  const { field, fieldState } = useController({
    ...props,
    rules: {
      validate: validatePhone('Fax'),
      ...props.rules,
    },
  });

  return (
    <PhoneInput
      {...field}
      error={!!fieldState.error?.message}
      helperText={fieldState.error?.message}
      id={field.name}
      label={label}
      required={!!props.rules?.required}
      size="small"
      type={type}
    />
  );
}

export function AddressLine1Input<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  onAddressChange,
  transformInitialAddressValue,
  watchFieldName,
  label = 'Street address',
  ...props
}: Omit<FormInputProps<TFieldValues>, 'type'> & {
  onAddressChange: (smartyStreetsAddress: SmartyStreetsPrediction) => void;
  watchFieldName: TName;
  transformInitialAddressValue: (address: FieldPathValue<TFieldValues, TName>) => SmartyStreetsPrediction;
}) {
  const shipping = useWatch({ control: props.control, name: watchFieldName });
  const initialAddressValue = useMemo(
    () => transformInitialAddressValue(shipping),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipping],
  );
  const { field, fieldState } = useController(props);

  return (
    <AddressAutocomplete
      addressValue={field.value}
      errorText={fieldState.error?.message}
      id={field.name}
      initialAddressValue={initialAddressValue}
      onBlur={field.onBlur}
      onFocus={() => undefined}
      onSelect={(value: SmartyStreetsPrediction | null) => {
        if (!value) {
          return;
        }

        onAddressChange(value);
      }}
      placeholder={label}
      required={!!props.rules?.required}
      setAddressValue={field.onChange}
    />
  );
}

export function AddressStateInput<TFieldValues extends FieldValues>({
  label,
  ...props
}: Omit<FormInputProps<TFieldValues>, 'type'>) {
  const { field, fieldState } = useController(props);

  return (
    <Select
      {...field}
      error={!!fieldState.error?.message}
      errorText={fieldState.error?.message}
      id={field.name}
      label={label}
      options={states}
      placeholder={label}
      required={!!props.rules?.required}
      size="small"
    />
  );
}

export function DateInput<TFieldValues extends FieldValues>({
  label,
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  ...props
}: Omit<FormInputProps<TFieldValues>, 'type'>) {
  const { field, fieldState } = useController({
    ...props,
    rules: {
      validate: (value: Date) => {
        if (!value) return 'Date is required';
        if (!isValid(value)) return 'Date must be a valid date';

        return undefined;
      },
      ...props.rules,
    },
  });

  return (
    <DatePicker
      {...field}
      error={!!fieldState.error?.message}
      helperText={fieldState.error?.message}
      label={label}
      onBlur={() => {
        field.onBlur();
        onBlur?.();
      }}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      required={!!props.rules?.required}
      size="small"
    />
  );
}

export function NpiInput<TFieldValues extends FieldValues>({
  onProviderChange,
  label,
  ...props
}: Omit<FormInputProps<TFieldValues>, 'type'> & {
  onProviderChange: (provider: NpiProvider | null) => void;
}) {
  const { isValidatingNpi, validateNpi } = useValidateNpi();

  const validateNpiAndSetProvider = useCallback(async function validateNpiAndSetProvider(value: string) {
    try {
      await validateNpi(value, (providerValues) => onProviderChange?.(providerValues), false);
    } catch (error) {
      onProviderChange?.(null);

      return false;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { field, fieldState } = useController({
    ...props,
    rules: {
      validate: async (value) => {
        const isValid = await validateNpiAndSetProvider(value);

        return isValid || 'Invalid NPI Number';
      },
      minLength: {
        value: 10,
        message: 'NPI Number must be 10 digits long',
      },
      maxLength: {
        value: 10,
        message: 'NPI Number must be 10 digits long',
      },
      required: 'NPI Number is required',
    },
  });

  return (
    <TextField
      {...field}
      disabled={isValidatingNpi}
      error={!!fieldState.error?.message}
      helperText={fieldState.error?.message}
      label={label}
      required
      size="small"
      type="number"
    />
  );
}

export function DiagnosisInput<TFieldValues extends FieldValues>(
  props: Omit<FormInputProps<TFieldValues>, 'type' | 'label'>,
) {
  const { field, fieldState } = useController(props);

  return (
    <DiagnosisCodeAutocomplete
      {...field}
      error={fieldState.error && 'Please search and select diagnosis codes'}
      multiple={false}
      selectedDiagnosisCodes={field.value}
      setSelectedDiagnosisCodes={field.onChange}
      size="small"
    />
  );
}

export function HcpcsInput<TFieldValues extends FieldValues>(
  props: Omit<FormInputProps<TFieldValues>, 'type' | 'label'>,
) {
  const { field, fieldState } = useController(props);

  return (
    <HcpcsCodeAutocomplete
      {...field}
      error={fieldState.error && 'Please search and select a HCPCS code'}
      multiple={false}
      selectedHcpcsCodes={field.value}
      setSelectedHcpcsCodes={field.onChange}
    />
  );
}

export function PatientAutocompleteInput<TFieldValues extends FieldValues>({
  orgId,
  ...props
}: { orgId: string } & Omit<FormInputProps<TFieldValues>, 'type' | 'label'>) {
  const { field, fieldState } = useController(props);

  return (
    <PatientAutocomplete
      {...field}
      error={fieldState.error && 'Please search and select a patient'}
      orgId={orgId}
      selectedPatient={field.value}
      setSelectedPatient={field.onChange}
      size="small"
    />
  );
}

export function ProductSubcategoryInput<TFieldValues extends FieldValues>({
  label,
  ...props
}: Omit<FormInputProps<TFieldValues>, 'type'>) {
  const { field, fieldState } = useController(props);

  const intakeSubcategories = useIntakeSubcategories();
  const intakeSubcategoryOptions = useIntakeSubcategoryOptions(intakeSubcategories.data);

  return (
    <Select
      {...field}
      disabled={intakeSubcategories.isLoading}
      error={!!fieldState.error?.message}
      errorText={fieldState.error?.message}
      id={field.name}
      label={label}
      options={intakeSubcategoryOptions}
      placeholder={label}
      required={!!props.rules?.required}
      size="small"
    />
  );
}

type IntakeSubcategory = NonNullable<NonNullable<GetIntakeSubcategoriesQuery['intakeSubcategories']>[number]>;

const isSubcategorySelectable = (subcategory: IntakeSubcategory) => !!subcategory.activeProductForm?.formJson;

const useIntakeSubcategoryOptions = (intakeSubcategories: IntakeSubcategory[] | undefined) => {
  return useMemo(() => {
    return [
      {
        label: 'None',
        value: '',
      },
      ...(intakeSubcategories?.filter(isSubcategorySelectable).map((subcategory) => ({
        label: subcategory.name,
        value: subcategory.id,
      })) ?? []),
    ];
  }, [intakeSubcategories]);
};
