import { ClipboardButton } from './ClipboardButton';
import { Colors } from '../theme';
import { displayDate } from '../utils/date';
import { RiCheckboxCircleLine, RiErrorWarningLine } from '@remixicon/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/material';

interface OrderLineItemDeliveryStatusBase {
  hcpcs: string;
  description?: string;
  swapTitleAndDescription?: boolean;
  /**
   * Optional cb handler upon clipboard copy
   */
  onClipboardCopy?: (trackingNumber: string, error?: Error) => void;
  sx?: SxProps;
}

export interface OrderLineItemDeliveryStatusDefault extends OrderLineItemDeliveryStatusBase {
  variant: 'default';
  componentSlots?: {
    right?: React.ReactNode;
  };
}
interface OrderLineItemDeliveryStatusShipped extends OrderLineItemDeliveryStatusBase {
  variant: 'shipped';
  statusText?: string;
  error?: boolean;
  trackingNumber?: string;
  trackingUrl?: string;
  onTrackingNumberClick?: (trackingNumber: string) => void;
}

interface OrderLineItemDeliveryStatusDelivered extends OrderLineItemDeliveryStatusBase {
  variant: 'delivered';
  statusText?: string;
  deliveryDate?: Date | null;
  trackingNumber?: string;
  trackingUrl?: string;
  onTrackingNumberClick?: (trackingNumber: string) => void;
}

export type OrderLineItemDeliveryStatusProps =
  | OrderLineItemDeliveryStatusDefault
  | OrderLineItemDeliveryStatusShipped
  | OrderLineItemDeliveryStatusDelivered;

const bgColorToVariantMap = {
  default: Colors.newGray,
  shipped: Colors.newGreen,
  error: 'rgba(244, 85, 85, 0.085)',
  delivered: Colors.newGreen,
} as const;

export const OrderLineItemDeliveryStatus = (props: OrderLineItemDeliveryStatusProps) => {
  const isShippingError = props.variant === 'shipped' && !!props.error;
  const backgroundColor = bgColorToVariantMap[isShippingError ? 'error' : props.variant];
  const trackingNumber =
    props.variant !== 'default' && props.trackingNumber ? props.trackingNumber.toUpperCase() : undefined;
  const itemTitle = props.swapTitleAndDescription ? props.description : props.hcpcs;
  const itemSubtitle = props.swapTitleAndDescription ? props.hcpcs : props.description;

  return (
    <Box
      sx={{
        bgcolor: backgroundColor,
        borderRadius: '2px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 12px',
        ...props.sx,
      }}
    >
      <Grid alignItems="center" container wrap="nowrap">
        {(props.variant === 'shipped' || props.variant === 'delivered') && (
          <Grid item>
            <Box marginRight="10px">
              {props.variant === 'shipped' && props.error ? (
                <Tooltip enterTouchDelay={0} placement="top" title="This tracking number cannot be confirmed.">
                  <Box>
                    <RiErrorWarningLine color={Colors.muiError} size={15} />
                  </Box>
                </Tooltip>
              ) : (
                <RiCheckboxCircleLine color="#50DCB7" size={15} />
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs zeroMinWidth>
          <Grid container spacing={3} wrap="nowrap">
            <Grid item xs zeroMinWidth>
              <Typography noWrap title={itemTitle}>
                <Box component="span" fontWeight="600">
                  {itemTitle || '--'}
                </Box>
              </Typography>
              {itemSubtitle && (
                <Typography color="textSecondary" noWrap title={itemSubtitle}>
                  {itemSubtitle}
                </Typography>
              )}
            </Grid>

            {props.variant === 'shipped' && (
              <Grid item xs zeroMinWidth>
                <Box textAlign="right">
                  <Typography color={props.error ? 'error' : 'textSecondary'} noWrap>
                    <Box component="span" fontWeight={props.error ? undefined : '600'}>
                      {props.statusText || 'Shipped'}
                    </Box>
                  </Typography>

                  {props.trackingNumber && (
                    <Box alignItems="center" display="flex" justifyContent="flex-end">
                      <Typography color="textSecondary" noWrap title={trackingNumber}>
                        {props.trackingUrl ? (
                          <Link
                            color="inherit"
                            href={props.trackingUrl}
                            onClick={() =>
                              props.trackingNumber ? props.onTrackingNumberClick?.(props.trackingNumber) : undefined
                            }
                            rel="noopener"
                            target="_blank"
                          >
                            {trackingNumber}
                          </Link>
                        ) : (
                          trackingNumber
                        )}
                      </Typography>

                      <ClipboardButton
                        onClipboardCopy={props.onClipboardCopy}
                        value={props.trackingNumber.toUpperCase()}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            )}

            {props.variant === 'default' && (
              <>
                {props.componentSlots?.right && (
                  <Grid item xs zeroMinWidth>
                    {props.componentSlots.right}
                  </Grid>
                )}
              </>
            )}

            {props.variant === 'delivered' && (
              <Grid item xs zeroMinWidth>
                <Box textAlign="right">
                  <Typography color="textSecondary" noWrap>
                    <Box component="span" fontWeight="600">
                      {props.statusText || 'Delivered'}
                    </Box>
                  </Typography>

                  <Typography color="textSecondary" noWrap>
                    {displayDate(props.deliveryDate) ?? '--'}
                  </Typography>

                  {props.trackingNumber && (
                    <Box alignItems="center" display="flex" justifyContent="flex-end">
                      <Typography color="textSecondary" noWrap title={trackingNumber}>
                        {props.trackingUrl ? (
                          <Link
                            color="inherit"
                            href={props.trackingUrl}
                            onClick={() =>
                              props.trackingNumber ? props.onTrackingNumberClick?.(props.trackingNumber) : undefined
                            }
                            rel="noopener"
                            target="_blank"
                          >
                            {trackingNumber}
                          </Link>
                        ) : (
                          trackingNumber
                        )}
                      </Typography>

                      <ClipboardButton
                        onClipboardCopy={props.onClipboardCopy}
                        value={props.trackingNumber.toUpperCase()}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const defaultProps: OrderLineItemDeliveryStatusProps = {
  variant: 'default',
  hcpcs: '',
};

OrderLineItemDeliveryStatus.defaultProps = defaultProps;

OrderLineItemDeliveryStatus.displayName = 'OrderLineItemDeliveryStatus';

export default OrderLineItemDeliveryStatus;
