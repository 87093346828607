import * as Types from '../types.generated.js';

import { IntakeOrderFragmentDoc, IntakeOrderSignerFragmentDoc } from '../fragments/IntakeOrderFragment.generated.js';
import { ProductFragmentDoc } from '../queries/getProducts.generated.js';
import { IntakePatientFragmentDoc } from '../fragments/IntakePatientFragment.generated.js';
import {
  PatientFragmentDoc,
  PatientInsuranceFragmentDoc,
  AddressFragmentDoc,
  PatientSlimFragmentDoc,
  PatientSlimSansAddressFragmentDoc,
} from '../fragments/PatientFragment.generated.js';
import { ProviderFragmentDoc } from '../fragments/ProviderFragment.generated.js';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type CreateOmniOrderMutationVariables = Types.Exact<{
  input: Types.OmniCreateOrderInput;
}>;

export type CreateOmniOrderMutation = {
  __typename?: 'Mutation';
  order?: {
    __typename?: 'OmniCreateOrderMutation';
    order?: {
      __typename?: 'OrderType';
      careManagerName?: string | null;
      dischargeAt?: string | null;
      emergencyContactRelationship?: string | null;
      friendlyId?: string | null;
      id: string;
      intakeType: Types.AppOrderIntakeTypeChoices;
      note?: string | null;
      shippingDetails?: string | null;
      serviceType?: Types.AppOrderServiceTypeChoices | null;
      urgent: boolean;
      appointment?: { __typename?: 'AppointmentType'; appointmentDate: string } | null;
      currentAnswer?: {
        __typename?: 'OrderIntakeAnswerType';
        productFormJson?: string | null;
        productAnswerJson?: string | null;
        clinicalFormJson?: string | null;
        clinicalAnswerJson?: string | null;
      } | null;
      currentSigners?: Array<{
        __typename?: 'OrderSignerType';
        id: string;
        emailAddress?: string | null;
        faxNumber?: string | null;
        fullName?: string | null;
        isRequestor: boolean;
        isSigner: boolean;
        isPrescriber: boolean;
        nameSigned?: string | null;
        phoneNumber?: string | null;
        smsNumber?: string | null;
        signedAt?: string | null;
        signingMethod: Types.AppOrderSignerSigningMethodChoices;
        signingRole: Types.AppOrderSignerSigningRoleChoices;
        supervisingPhysician?: string | null;
        user?: { __typename?: 'UserSlimType'; id: string } | null;
      } | null> | null;
      documents: Array<{
        __typename?: 'DocumentType';
        id: string;
        originalFileName?: string | null;
        url?: string | null;
        metadata?: string | null;
      }>;
      emergencyContact?: {
        __typename?: 'PersonType';
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
      } | null;
      falconStatus?: { __typename?: 'OrderStatusHistoryType'; status: Types.AppOrderStatusHistoryStatusChoices } | null;
      intakeCategory?: { __typename?: 'IntakeCategoryType'; id: string; name: string } | null;
      intakeSubcategory?: {
        __typename?: 'IntakeSubcategoryType';
        id: string;
        name: string;
        syncId: string;
        activeProductForm?: {
          __typename?: 'IntakeSubcategoryProductFormType';
          id: string;
          formJson?: string | null;
        } | null;
      } | null;
      lineItems: Array<{
        __typename?: 'OrderLineItemType';
        id: string;
        displayText: string;
        note?: string | null;
        quantity: number;
        productItem?: { __typename?: 'OrderProductItemType'; id: string } | null;
        patientPrescriptionItem?: {
          __typename?: 'PatientPrescriptionItemType';
          lengthOfNeed: number;
          diagnoses?: Array<{ __typename?: 'DiagnosisType'; code: string; description?: string | null } | null> | null;
        } | null;
        hcpcsCode?: {
          __typename?: 'HcpcsType';
          code: string;
          imgUrl?: string | null;
          friendlyDescription?: string | null;
        } | null;
      }>;
      productItems?: Array<{
        __typename?: 'OrderProductItemType';
        id: string;
        productForm?: string | null;
        productOptions?: string | null;
        product?: {
          __typename?: 'ProductType';
          description: string;
          id: string;
          imageUrl?: string | null;
          name: string;
          subproducts?: Array<{
            __typename?: 'SubproductType';
            hcpcs: { __typename?: 'HcpcsType'; code: string };
          } | null> | null;
        } | null;
      } | null> | null;
      patient: {
        __typename?: 'PatientType';
        id: string;
        emailAddress?: string | null;
        dateOfBirth?: string | null;
        firstName: string;
        middleName: string;
        lastName: string;
        sex?: Types.SexEnum | null;
        heightInInches?: number | null;
        weightInPounds?: number | null;
        homePhoneNumber?: string | null;
        mobilePhoneNumber?: string | null;
        patientWaiver?: {
          __typename?: 'PatientWaiverType';
          waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
        } | null;
        guardian?: {
          __typename?: 'PersonType';
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
        } | null;
        languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
        primaryCareProvider?: {
          __typename?: 'ProviderType';
          id: string;
          firstName: string;
          lastName: string;
          npi: string;
        } | null;
        primaryCareProviderContact?: {
          __typename?: 'ContactType';
          id: string;
          emailAddress?: string | null;
          phoneNumber?: string | null;
          smsNumber?: string | null;
          faxNumber?: string | null;
        } | null;
        primaryInsurance?: {
          __typename?: 'PatientInsuranceType';
          status: Types.AppPatientInsuranceStatusChoices;
          policyNumber: string;
          insurer: {
            __typename?: 'InsurerType';
            id: string;
            insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
            name: string;
            display: string;
            requiresTitleXix: boolean;
            parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
          };
        } | null;
        secondaryInsurance?: {
          __typename?: 'PatientInsuranceType';
          status: Types.AppPatientInsuranceStatusChoices;
          policyNumber: string;
          insurer: {
            __typename?: 'InsurerType';
            id: string;
            insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
            name: string;
            display: string;
            requiresTitleXix: boolean;
            parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
          };
        } | null;
        tertiaryInsurance?: {
          __typename?: 'PatientInsuranceType';
          status: Types.AppPatientInsuranceStatusChoices;
          policyNumber: string;
          insurer: {
            __typename?: 'InsurerType';
            id: string;
            insurerCategoryDirect?: Types.AppInsurerInsurerCategoryDirectChoices | null;
            name: string;
            display: string;
            requiresTitleXix: boolean;
            parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
          };
        } | null;
        shippingAddress?: {
          __typename?: 'AddressType';
          addressLine1: string;
          addressLine2: string;
          city: string;
          state: string;
          postalCode: string;
          id: string;
        } | null;
      };
      practice?: { __typename?: 'PracticeSlimType'; id: string; name: string } | null;
      primaryDocument?: {
        __typename?: 'DocumentType';
        id: string;
        originalFileName?: string | null;
        url?: string | null;
      } | null;
      providerContact?: {
        __typename?: 'ContactType';
        emailAddress?: string | null;
        faxNumber?: string | null;
        phoneNumber?: string | null;
        smsNumber?: string | null;
      } | null;
      referringProvider?: {
        __typename?: 'ProviderType';
        firstName: string;
        lastName: string;
        npi: string;
        emailAddress?: string | null;
        faxNumber?: string | null;
        id: string;
        phoneNumber?: string | null;
        smsNumber?: string | null;
      } | null;
      shippingAddress?: {
        __typename?: 'AddressType';
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: string;
        id: string;
      } | null;
      deferredSupplierSuggestion?: {
        __typename?: 'DeferredOrderSupplierSuggestionType';
        faxNumber: string;
        id: string;
        phoneNumber?: string | null;
        supplierName: string;
      } | null;
      supplierSuggestions?: Array<{
        __typename?: 'OrderSupplierSuggestionType';
        id: string;
        source?: Types.AppOrderSupplierSuggestionSourceChoices | null;
        supplier: { __typename?: 'OptimizedNetworkSupplierType'; name: string; phoneNumber?: string | null };
      } | null> | null;
      addresses?: Array<{
        __typename?: 'OrderAddressType';
        id: string;
        addressType: Types.AppOrderAddressAddressTypeChoices;
        address?: {
          __typename?: 'AddressType';
          addressLine1: string;
          addressLine2: string;
          city: string;
          state: string;
          postalCode: string;
          id: string;
        } | null;
      } | null> | null;
      user?: { __typename?: 'UserTypeForAdminView'; id: string } | null;
    } | null;
    error?: { __typename?: 'ErrorType'; message: string } | null;
  } | null;
};

export const CreateOmniOrderDocument = `
    mutation createOmniOrder($input: OmniCreateOrderInput!) {
  order: omniCreateOrder(inputData: $input) {
    order {
      ...IntakeOrderFragment
    }
    error {
      message
    }
  }
}
    ${IntakeOrderFragmentDoc}
${IntakeOrderSignerFragmentDoc}
${ProductFragmentDoc}
${IntakePatientFragmentDoc}
${PatientFragmentDoc}
${PatientInsuranceFragmentDoc}
${AddressFragmentDoc}
${ProviderFragmentDoc}`;

export const useCreateOmniOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateOmniOrderMutation, TError, CreateOmniOrderMutationVariables, TContext>,
) => {
  return useMutation<CreateOmniOrderMutation, TError, CreateOmniOrderMutationVariables, TContext>({
    mutationKey: ['createOmniOrder'],
    mutationFn: (variables?: CreateOmniOrderMutationVariables) =>
      fetcher<CreateOmniOrderMutation, CreateOmniOrderMutationVariables>(CreateOmniOrderDocument, variables)(),
    ...options,
  });
};

useCreateOmniOrderMutation.getKey = () => ['createOmniOrder'];

useCreateOmniOrderMutation.fetcher = (variables: CreateOmniOrderMutationVariables, options?: RequestInit['headers']) =>
  fetcher<CreateOmniOrderMutation, CreateOmniOrderMutationVariables>(CreateOmniOrderDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateOmniOrderMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { omniCreateOrder }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateOmniOrderMutation = (
  resolver: GraphQLResponseResolver<CreateOmniOrderMutation, CreateOmniOrderMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<CreateOmniOrderMutation, CreateOmniOrderMutationVariables>('createOmniOrder', resolver, options);
